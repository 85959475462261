.page_login {
	section.greetings {
		margin-bottom: 200px;
		h2 {
			font-size: 22px;
			margin-bottom: 30px;
		}
		p {
			font-size: 16px;
			line-height: 2;
			margin-bottom: 1.25em;
			&.ps {
				margin-top: 4em;
			}
		}
		@include lg {
			text-align: left;
			br.wbr {
				display: none;
			}
		}
		@include sm {
			br {
				display: none;
			}
		}
	}
	section.special_favor{
		margin-bottom: 100px;
		h2{
			font-size: 20px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 10px;
		}
		.descripton{
			text-align: center;
			margin-bottom: 20px;
		}
	}
	section.entry_condition{
		text-align: center;
		h2{
			font-size: 20px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 10px;
		}
		.block{
			display: inline-block;
			text-align: left;
			background-color: #F4F3EE;
			padding: 20px 30px;
			ul{
				list-style: disc;
				padding-left: 1.25em;
				li{
					margin-bottom: 10px;
				}
			}
		}
		.btn_wrap{
			text-align: center;
			margin-top: 30px;
			.btn_entry{
				display: inline-block;
				background-color: #B9293B;
				color: white;
				font-weight: bold;
				padding: 20px 15px;
				border: none;
				margin-top: 50px;
				width: 100%;
				font-size: 30px;
				cursor: pointer;
				.f_small{
					font-size: 80%;
				}
			}
		}
	}
}
