.page_test {
	h2 {
		counter-reset: dummy;
	}
	.dummy {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: gainsboro;
		min-height: 50px;
		counter-increment: dummy;
		&::after {
			content: counter(dummy);
		}
	}

}