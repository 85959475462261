#footer {
	background-color: #020202;
	color: white;
	height: 207px;
	background-image: url(../images/footer.jpg);
	background-repeat: no-repeat;
	background-size: auto 207px;
	background-position: center;
	@include hdpi(2) {
		background-image: url(../images/footer@2x.jpg);
	}
	@include md {
		background-position: left;
	}
	@include sm {
		background-size: auto 150px;
		background-position: left bottom;
		height: 150px;
	}
	@include xs {
		background-size: auto 110px;
	}
	.container,
	.wrapper {
		height: inherit;
	}
	.wrapper {
		position: relative;
		@include sm {

		}
		.logo {
			position: absolute;
			top: 50px;
			left: 180px;
			@include sm {
				top: 30px;
				left: 120px;
			}
			@include xs {
				top: 20px;
				left: 10px;
			}
		}
		.links {
			position: absolute;
			top: 50px;
			right: 0;
			font-size: 12px;
			text-align: right;
			@include sm {
				top: 30px;
			}
			@include xs {
				top: 30px;
			}
			a {
				color: inherit;
				@include sm {
					display: block;
					margin-bottom: 10px;
				}
				& + a {
					margin-left: 2em;
					@include sm {
						margin-left: 0;
					}
				}
			}
		}
		.copyright {
			position: absolute;
			bottom: 30px;
			right: 0;
			font-size: 10px;
			@include sm {
				bottom: 20px;
			}
			@include xs {
				bottom: 15px;
			}
		}
	}
}
