#header {
	height: 167px;
	background-color: #1b0b02;
	background-repeat: no-repeat;
	&.header_page {
		background-size: auto 100%;
		background-position: center;
		background-image: url(../images/header.jpg);
		@include hdpi(2) {
			background-image: url(../images/header@2x.jpg);
		}

		@include min-screen(1568px) {
			background-size: 90% auto;
			background-position: center left;
		}
		@include lg {
			background-position: center right 40%;
		}
		@include sm {
			background-position: center left;
		}
		@include xs {
			height: 100px;
			background-position: bottom right 30%;
		}
	}
	&.header_home,
	&.header_login {
		background-size: cover;
		background-image: url(../images/header_lg.jpg);
		@include hdpi(2) {
			background-image: url(../images/header_lg@2x.jpg);
		}
		.title {
			width: 62.340425531914894%;
		}
	}
	&.header_home {
		height: 42.796005706134094vw;
		min-height: 400px;
		max-height: 600px;
		background-position: bottom 20% right 48%;
		@include xs {
			height: 200px;
			min-height: 0;
			background-position: bottom right 30%;
		}
		.title {
			position: absolute;
			top: 37%;
			min-width: 370px;
			@include xs {
				display: none;
			}
		}
	}
	&.header_login {
		height: auto;
		background-position: bottom right 34%;
		@include xs {
			background-position: top left;
			background-size: auto 47.885075818036712vw;
			padding-top: 47.885075818036712vw;
		}
		.wrapper {
			padding-top: 67px;
			padding-bottom: 50px;
			@include xs {
				position: static;
				padding: 0 0 20px;
			}
		}
		.title {
			margin-bottom: 50px;
			@include xs {
				position: absolute;
				top: 23.942537909018356vw;
				transform: translateY(-50%);
			}
			img {
				display: block;
			}
		}
		.login_form {
			display: flex;
			flex-direction: column;
			width: 56.276595744680851%;
			background-color: rgba(255,255,255,.47);
			color: white;
			padding: 15px 60px 30px;
			margin-bottom: 20px;
			@include md {
				padding: 15px 30px 30px;
			}
			@include xs {
				width: auto;
				padding: 15px 23px 20px;
				margin-bottom: 0;
			}
			h2 {
				font-size: 24px;
				margin-bottom: 10px;
				@include xs {
					font-size: 18px;
				}
			}
			.swpm-login-form-inner {
				display: flex;
				flex-direction: column;
				.swpm-username-input,
				.swpm-password-input {
					margin-bottom: 10px;
					input {
						width: 100%;
						font-size: 14px;
						padding: 10px;
						@include xs {
							padding: 5px 10px;
						}
					}
				}
				.swpm-remember-me {
					margin-bottom: 5px;
					.swpm-remember-checkbox,
					.swpm-rember-label {
						font-size: 12px;
						vertical-align: middle;
						@include xs {
							font-size: 14px;
						}
					}
				}
				.swpm-login-submit,
				.swpm-join-us-link {
					.swpm-login-form-submit,
					.swpm-login-form-register-link {
						display: block;
						width: 100%;
						background: #658E3B;
						color: white;
						font-size: 16px;
						font-weight: bold;
						text-align: center;
						line-height: 1.5;
						padding: 8px 15px;
						border: none;
					}
					.swpm-login-form-register-link {
						background-color: #B9293B;
					}
				}
				.swpm-forgot-pass-link {
					font-size: 12px;
					margin-bottom: 10px;
					@include xs {
						font-size: 14px;
					}
					.swpm-login-form-pw-reset-link {
						color: inherit;
					}
				}
				.swpm-login-action-msg {
					margin-bottom: 10px;
					.swpm-login-widget-action-msg {
						color: crimson;
						font-weight: normal;
						text-shadow: 0px 1px 1px rgba(0,0,0,.25);
					}
				}
				.swpm-username-label,
				.swpm-username-input,
				.swpm-password-label,
				.swpm-password-input {
					order: 1;
				}
				.swpm-remember-me,
				.swpm-forgot-pass-link {
					order: 2;
				}
				.swpm-before-login-submit-section,
				.swpm-login-submit,
				.swpm-join-us-link {
					order: 3;
				}

			}

		}
	}

	.container, .wrapper {
		height: inherit;
		min-height: inherit;
		max-height: inherit;
	}
	.wrapper {
		position: relative;
		padding-top: 35px;
		@include xs {
			padding-top: 30px;
		}
	}
	.logo {
		display: inline-block;
		vertical-align: top;
		@include xs {
			max-width: 68%;
			margin-top: -3px;
		}
	}
	.radius_btn {
		vertical-align: top;
		margin-right: 5px;
		@include xs {
			display: none;
		}
	}
}
