// mixinsの読み込み
@import "./mixins/include.scss";
@import "_variables.scss";

/******************************************
* reset
******************************************/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	font-family: $gothic;
}
img,object,embed {max-width: 100%;}
html {
	overflow-y: scroll;
	font-size: 14px;
	line-height: 1.5;
}
/******************************************
* font-size
******************************************/
@mixin font-size($size) {
	font-size:#{72 + (7 * ($size - 10)) * 1%};
}
ul {list-style: none;}
a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	text-decoration: none;
}
table {border-collapse: collapse; border-spacing: 0;}
th {font-weight: bold; vertical-align: bottom;}
td {font-weight: normal; vertical-align: top;}
td, td img {vertical-align: top;}
.clickable,label,input[type=button],input[type=submit],input[type=file],button {cursor: pointer;}
button, input, select, textarea {margin: 0;}
button,input[type=button] {width: auto; overflow: visible;}

/******************************************
* ie11のみ適応
******************************************/
@mixin ie11() {
	@media all and (-ms-high-contrast:none){
		@content;
	}
}
/******************************************
* clearfix
******************************************/
.clearfix{
	@include clearfix();
	.left{
		float: left;
	}
	.right{
		float: right;
	}
}

/******************************************
* wrapper
******************************************/

// scroll-behavior：ブラウザのスクロールの振る舞いを決める
// 値「smooth」でChrome や Firefox はスムースになる
// これが使えない場合はJSでスムーススクロールを実現する
html {
	scroll-behavior: smooth;
}

// 左右に10pxの隙間を作る
// カラム系でネガティヴマージンを使っているので overflow: hidden;
// コンテナからはみ出したレイアウトにする場合は .container.over_visible とする
// はみ出したレイアウト＋カラムならカラムを .over_hidden で囲う
// .container > .wrapper とする 
.container {
	padding: 0 10px;
	overflow: hidden;
}
.over_visible {
	overflow: visible;
}
.over_hidden {
	overflow: hidden;
}

// コンテンツ幅
// .container > .wrapper とする
.wrapper {
	width: 100%;
	max-width: 940px;
	margin: auto;
	@include sp {
		width: auto;
	}
}



/******************************************
* img
******************************************/
img{
	max-width: 100%;
	height: auto;
}

/******************************************
* mt pd
******************************************/
@for $i from 0 through 30 {
	.mt#{$i * 5} {margin-top:#{$i * 5}px!important;}
	.mr#{$i * 5} {margin-right:#{$i * 5}px!important;}
	.mb#{$i * 5} {margin-bottom:#{$i * 5}px!important;}
	.ml#{$i * 5} {margin-left:#{$i * 5}px!important;}

	.pt#{$i * 5} {padding-top:#{$i * 5}px!important;}
	.pr#{$i * 5} {padding-right:#{$i * 5}px!important;}
	.pb#{$i * 5} {padding-bottom:#{$i * 5}px!important;}
	.pl#{$i * 5} {padding-left:#{$i * 5}px!important;}
}

@for $i from 0 through 20 {
	.mt#{$i * -5} {margin-top:#{$i * -5}px!important;}
	.mr#{$i * -5} {margin-right:#{$i * -5}px!important;}
	.mb#{$i * -5} {margin-bottom:#{$i * -5}px!important;}
	.ml#{$i * -5} {margin-left:#{$i * -5}px!important;}
}


/******************************************
* common-style
******************************************/
* {
	box-sizing: border-box;
}
body {
	background-color: white;
	color: #202124;
	font-size: 16px;
}

#main {
	padding: 40px 0 150px;
	@include xs {
		padding: 30px 0 120px;
	}
}

.breadcrumbs {
	list-style: none;
	display: flex;
	font-size: 13px;
	margin-bottom: 80px;
	@include xs {
		margin-bottom: 20px;
	}
	li + li::before {
		content: '>';
		margin: 0 .5em;
	}
}

.accent{
	color:$text-red;
}
.tac{text-align: center;}
.tar{text-align: right;}
.tal{text-align: left;}

.d_table {
	display: table;
	&.full_width {
		width: 100%;
	}
	&.layout_fixed {
		table-layout: fixed;
	}
	.d_cell {
		display: table-cell;
		vertical-align: top;
		&.v_middle {
			vertical-align: middle;
		}
	}
}
.text_danger {
	color: #AB322E;
}

.ws-pl {
	white-space: pre-line;
}

.hov_opacify {
	transition: opacity .25s;
	&:hover {
		opacity: .75;
	}
} 

.radius_btn {
	display: inline-block;
	min-width: 118px;
	background-color: #B9293B;
	color: white;
	font-size: 14px;
	text-align: center;
	padding: 5px 10px;
	border-radius: 100px;
	&_green {
		background-color: #658E3B;
	}
}

dl.archive {
	font-size: 16px;
	margin-bottom: 70px;
	div {
		display: flex;
		margin-bottom: 30px;
		@include sm {
			display: block;
			margin-bottom: 20px;
		}
	}
	dt {
		min-width: 8em;
		font-weight: bold;
		margin-right: 2.5em;
		@include sm {
			margin-bottom: 5px;
		}
	}
	dd {
		.cat_tag {
			display: inline-block;
			background-color: #31475E;
			color: white;
			font-size: 12px;
			padding: 2px 5px;
			border-radius: 5px;
			margin-right: 5px;
			vertical-align: 2px;
		}
		.info {
			@include xs {
				display: block;
				margin-top: 3px;
			}
		}
	}
}

.title {
	&_red {
		margin-bottom: 60px;
		@include xs {
			margin-bottom: 40px;
		}
		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			background-image: linear-gradient(to right, #F92525 30px, rgba(32,33,36,.1) 30px, rgba(32,33,36,.1));
			margin-top: 10px;
			@include xs {
				margin-top: 5px;
			}
		}
	}
	&_main {
		font-size: 32px;
		line-height: 1.25;
		@include xs {
			font-size: 26px;
		}
	}
	&_article {
		font-size: 21px;
		margin-bottom: 30px;
	}
}

.grid_menu {
	.grid {
		min-height: 320px;
		color: inherit;
		border-radius: 3px;
		box-shadow: 0 8px 20px rgba(224,224,224,.5);
		overflow: hidden;
		@include md {
			min-height: 0;
		}
		.thumb {
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				transition: transform .25s;
			}
		}
		&:hover {
			.thumb img {
				transform: scale(1.05);
			}
		}
		dl {
			padding: 20px 30px;
			@include sm {
				padding: 15px 20px;
			}
			@include xs {
				padding: 20px 20px;
			}
			dt {
				color: #31475E;
				font-size: 24px;
				font-weight: bold;
				text-align: center;
				margin-bottom: 20px;
			}
			dd {
				font-size: 16px;
				@include md {
					text-align: justify;
					br {
						display: none;
					}
				}
			}
		}
	}
}

.pagetop {
	margin: 40px 0;
	img {
		display: block;
		margin: 0 auto;
	}
}

.navigation.pagination[role="navigation"] {
	margin-bottom: 70px;
	.screen-reader-text {
		clip: rect(1px, 1px, 1px, 1px);
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
	}
}
dl.archive + .navigation.pagination[role="navigation"] {
	margin-top: -30px;
}

// カラム：lgで4、mdで3、smで2、xsで1カラムになる
.col5 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
	& > * {
		width: calc(100% / 5 - 5px * 2 - 1px);
		max-width: calc(100% / 5 - 5px * 2);
		flex: 1 0 auto;
		margin: 0 5px 10px;
	}
	@include lg {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 4 - 10px * 2 - 1px);
			max-width: calc(100% / 4 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
	@include md {
		& > * {
			width: calc(100% / 3 - 10px * 2 - 1px);
			max-width: calc(100% / 3 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
	@include sm {
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
	@include xs {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}

.col4 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	& > * {
		width: calc(100% / 4 - 10px * 2 - 1px);
		max-width: calc(100% / 4 - 10px * 2);
		flex: 1 0 auto;
		margin: 0 10px 20px;
	}
	@include md {
		& > * {
			width: calc(100% / 3 - 10px * 2 - 1px);
			max-width: calc(100% / 3 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
	@include sm {
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
	@include xs {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}

.col3 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	& > * {
		width: calc(100% / 3 - 10px * 2 - 1px);
		max-width: calc(100% / 3 - 10px * 2);
		flex: 1 0 auto;
		margin: 0 10px 20px;
	}
	@include sm {
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
	@include xs {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}

.col2 {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	& > * {
		width: calc(100% / 2 - 15px * 2 - 1px);
		max-width: calc(100% / 2 - 15px * 2);
		flex: 1 0 auto;
		margin: 0 15px 20px;
	}
	@include md {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
	@include xs {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}
// カラム間マージンの広い2カラム
.col2m {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -25px;
	& > * {
		width: calc(100% / 2 - 25px * 2 - 1px);
		max-width: calc(100% / 2 - 25px * 2);
		flex: 1 0 auto;
		margin: 0 25px 20px;
	}
	@include lg {
		margin: 0 -15px;
		& > * {
			width: calc(100% / 2 - 15px * 2 - 1px);
			max-width: calc(100% / 2 - 15px * 2);
			flex: 1 0 auto;
			margin: 0 15px 20px;
		}
	}
	@include md {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
	@include xs {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}

// .col系のデフォルトのカラム数を変更するときに使う
// 例）.col5 .col3lg .col2md .col1xs
/* lg */
.col4lg {
	@include lg {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 4 - 10px * 2 - 1px);
			max-width: calc(100% / 4 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col3lg {
	@include lg {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 3 - 10px * 2 - 1px);
			max-width: calc(100% / 3 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col2lg {
	@include lg {
		margin: 0 -15px;
		& > * {
			width: calc(100% / 2 - 15px * 2 - 1px);
			max-width: calc(100% / 2 - 15px * 2);
			margin: 0 15px 20px;
		}
	}
	@include md {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col1lg {
	@include lg {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}

/* md */
.col4md {
	@include md {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 4 - 10px * 2 - 1px);
			max-width: calc(100% / 4 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col3md {
	@include md {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 3 - 10px * 2 - 1px);
			max-width: calc(100% / 3 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col2md {
	@include md {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col1md {
	@include md {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}

/* sm */
.col3sm {
	@include sm {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 3 - 10px * 2 - 1px);
			max-width: calc(100% / 3 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col2sm {
	@include sm {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col1sm {
	@include sm {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}

/* xs */
.col2xs {
	@include xs {
		margin: 0 -10px;
		& > * {
			width: calc(100% / 2 - 10px * 2 - 1px);
			max-width: calc(100% / 2 - 10px * 2);
			margin: 0 10px 20px;
		}
	}
}
.col1xs {
	@include xs {
		margin: 0;
		& > * {
			width: 100%;
			max-width: none;
			margin: 0 0 20px;
		}
	}
}

@keyframes fade-in {
	from { opacity: 0 } to { opacity: 1 }
}
@keyframes slide-downwards {
	from { transform: translateY(-100%) } to { transform: none }
}
@keyframes focus {
	from { filter: blur(10px) } to { filter: none }
}
