.page_register {
	.breadcrumbs {
		margin-bottom: 25px;
		@include xs {
			margin-bottom: 20px;
		}
	}
	#message.error {
		color: #B9293B;
		background: lavenderblush;
		padding: 20px 30px;
		margin-bottom: 30px;
		@include xs {
			padding: 15px 10px;
		}
		ul {
			list-style: disc;
			padding-left: 1.5em;
			margin-top: 1em;
			@include xs {
				padding-left: 1.25em;
			}
		}
	}
	.steps {
		display: table;
		table-layout: fixed;
		width: 100%;
		list-style: none;
		margin-bottom: 50px;
		li {
			display: table-cell;
			position: relative;
			height: 50px;
			background-color: #658E3B;
			color: white;
			font-size: 24px;
			font-weight: bold;
			text-align: center;
			vertical-align: middle;
			padding-right: 15px;
			@include xs {
				height: 40px;
				font-size: 18px;
			}
			&.curr {
				background-color: #B9293B;
			}
			&::before,
			&::after {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 14px 15px;
				right: -2px;
				@include xs {
					border-width: 12px;
				}
			}
			&::before {
				top: -1px;
				border-color: white white transparent transparent;
			}
			&::after {
				bottom: -1px;
				border-color: transparent white white transparent;
			}
		}
	}
	.text {
		p {
			margin-bottom: 1.5em;
		}
		&.notes {
			background-color: #F4F3EE;
			padding: 20px 30px;
			margin-bottom: 60px;
			@include xs {
				padding: 15px;
				margin-bottom: 40px;
			}
			h3 {
				margin-bottom: 20px;
				@include xs {
					margin-bottom: 15px;
				}
			}
			ul {
				list-style: disc;
				padding-left: 1.25em;
				li {
					margin-bottom: 10px;
				}
			}
		}
	}
	#swpm-registration-form {
		table, tr, th, td, label {
			display: block;
		}
		tbody {
			display: flex;
			flex-direction: column;
			.swpm-registration-username-row,
			.swpm-registration-email-row,
			.swpm-registration-password-row,
			.swpm-registration-password-retype-row,
			.swpm-registration-lastname-row {
				order: 1;
			}
			.swpm-registration-firstname-row {
				order: 2;
			}

		}
		label {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 10px;
			@include xs {
				margin-bottom: 5px;
			}
		}
		input {
			width: 100%;
			font-size: 30px;
			padding: 10px 15px;
			border: solid 6px #DEDDDE;
			@include xs {
				font-size: 20px;
				padding: 5px 10px;
			}
			&[type="submit"] {
				background-color: #B9293B;
				color: white;
				font-weight: bold;
				padding: 20px 15px;
				border: none;
				margin-top: 50px;
				@include xs {
					padding: 15px;
				}
			}
		}
		tr {
			margin-bottom: 35px;
			@include xs {
				margin-bottom: 25px;
			}
		}
		.swpm-registration-membership-level-row {
			display: none;
		}
	}
	.btn_to_top_page {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 133px;
		font-size: 40px;
		padding: 0 25px;
		background-color: #190C02;
		background-image: url(../images/btn_to_top_page.jpg);
		background-repeat: no-repeat;
		background-position: right;
		background-size: cover;
		color: white;
		font-size: 36px;
		text-align: center;
		border: none;
		margin-top: 50px;
		@include hdpi(2) {
			background-image: url(../images/btn_to_top_page@2x.jpg);
		}
		&::before {
			content: "サロントップページへ行く!";
		}
		@include sm {
			height: 90px;
			font-size: 32px;
		}
		@include xs {
			height: 80px;
			font-size: 28px;
			&::before {
				content: "サロントップへ";
			}
		}
	}

	.swpm-payment-button{
		.stripe-button-el {
	        display: block;
	        margin: auto;
	        span{
	            height: 60px;
	            line-height: 60px;
	            font-size: 26px;
	            padding: 0 50px;
	        }
	    }
	}

	.swpm-registration-widget-form{
		max-width: 640px;
		margin: auto;
		.formError {
			@include md{
				position: static!important;
				margin-top: 0!important;
			}
		}
	}
}