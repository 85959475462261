.page_home {
	p.welcome {
		color: #B9293B;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 50px;
		@include xs {
			margin-bottom: 40px;
		}
	}
	.btn_to_payment {
		display: block;
		font-size: 30px;
		background-color: #B9293B;
		color: white;
		font-weight: bold;
		text-align: center;
		padding: 20px 15px;
		border: none;
		margin-top: 50px;
		@include xs {
			font-size: 20px;
			padding: 15px;
		}
	}
	section.whats_news {
		margin-bottom: 100px;
		.title_red {
			img, .ja {
				vertical-align: middle;
			}
			.ja {
				font-size: 18px;
				font-weight: normal;
				margin-left: 1em;
			}
		}
		.archive_btn {
			display: block;
			max-width: 240px;
			background-color: black;
			color: white;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			padding: 12px 20px;
			margin-left: auto;
		}
	}
}
