
/* mixinsの読み込み */
@import "mixins/include.scss";
/* variablesの読み込み */
@import "_variables.scss";

@import "_common.scss";

/* vendor */
//@import "vendor/slick.scss";

/* parts */
@import "parts/_header.scss";
@import "parts/_menu.scss";
@import "parts/_footer.scss";

/* page */
@import "page/_test.scss";
@import "page/_home.scss";
@import "page/_login.scss";
@import "page/_register.scss";
@import "page/_mypage.scss";
@import "page/_col1.scss";
@import "page/_col2.scss";
