#menu {
	position: absolute;
	width: 328px;
	top: 35px;
	right: 0;
	visibility: hidden;
	opacity: 0;
	z-index: 2147483647;
	transition: visibility .25s step-end, opacity .25s;
	@include xs {
		top: 30px;
		max-width: 300px;
	}
	&.show {
		visibility: visible;
		opacity: 1;
		transition: visibility .25s step-start, opacity .25s;
	}
	ul {
		color: white;
		font-size: 20px;
		@include xs {
			font-size: 16px;
		}
		li {
			background-color: rgba(65, 117, 5, .84);
			border-color: #417505;
			border-style: none solid solid;
			border-width: 1px;
			transition: background-color .25s;
			&:not(:first-of-type):hover {
				background-color: rgba(73, 132, 6, .84);
			}
			@include xs {
				background-color: rgba(65, 117, 5, .9);
				&:not(:first-of-type):hover {
					background-color: rgba(73, 132, 6, .9);
				}
			}
			&:first-of-type {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 20px;
				border-top-style: solid;
				#close_menu {
					position: relative;
					width: 50px;
					height: 50px;
					background: none;
					padding: 0;
					border: none;
					@include xs {
						width: 44px;
						height: 44px;
					}
					&::before,
					&::after {
						content: "";
						position: absolute;
						top: 0;
						left: 5px;
						bottom: 0;
						width: 2px;
						height: 20px;
						background-color: white;
						margin: auto 0;
					}
					&::before {
						transform: rotate(45deg);
					}
					&::after {
						transform: rotate(135deg);
					}
				}
				span {
					font-size: 14px;
				}
			}
			&.for_xs {
				display: none;
				@include xs {
					display: block;
				}
			}
			a {
				color: inherit;
				display: block;
				padding: 10px 20px;
				@include xs {
					padding: 10px 15px;
				}
			}
		}
	}
}
#open_menu {
	position: absolute;
	right: 0;
	width: 67px;
	background: transparent;
	padding: 0;
	border: none;
	margin: 0;
	@include xs {
		width: 48px;
	}
	.hamburger {
		position: relative;
		height: 10px;
		background-color: white;
		margin: 40px 0 3px;
		&::before,
		&::after {
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			height: 10px;
			background-color: white;
		}
		&::before {
			top: -40px;
		}
		&::after {
			top: -20px;
		}
		@include xs {
			height: 7px;
			margin: 28px 0 2px;
			&::before,
			&::after {
				height: 7px;
			}
			&::before {
				top: -28px;
			}
			&::after {
				top: -14px;
			}
		}
	}
	p {
		color: white;
		text-align: center;
		font-size: 14px;
		@include xs {
			font-size: 12px;
		}
	}
}
