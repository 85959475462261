.page_col1 {
	#swpm-pw-reset-form {
		label {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 10px;
			@include xs {
				margin-bottom: 5px;
			}
		}
		input {
			width: 100%;
			font-size: 30px;
			padding: 10px 15px;
			border: solid 6px #DEDDDE;
			@include xs {
				font-size: 20px;
				padding: 5px 10px;
			}
			&[type="submit"] {
				background-color: #B9293B;
				color: white;
				font-weight: bold;
				padding: 20px 15px;
				border: none;
				margin-top: 50px;
				@include xs {
					padding: 15px;
				}
			}
		}

	}
	#swpm-editprofile-form {
		table, tr, th, td, label {
			display: block;
		}
		tbody {
			display: flex;
			flex-direction: column;
			.swpm-profile-username-row > td:last-of-type {
				font-size: 30px;
				&::after {
					content: " さん";
				}
			}
			.swpm-profile-username-row,
			.swpm-profile-email-row,
			.swpm-profile-password-row,
			.swpm-profile-password-retype-row,
			.swpm-profile-lastname-row {
				order: 1;
			}
			.swpm-profile-firstname-row {
				order: 2;
			}
			.swpm-profile-phone-row,
			.swpm-profile-street-row,
			.swpm-profile-city-row,
			.swpm-profile-state-row,
			.swpm-profile-zipcode-row,
			.swpm-profile-country-row,
			.swpm-profile-company-row,
			.swpm-profile-membership-level-row {
				display: none;
			}
		}
		label {
			font-size: 16px;
			font-weight: bold;
			margin-bottom: 10px;
			@include xs {
				margin-bottom: 5px;
			}
			&[for="user_name"]::after {
				content: none;
				display: inline-block;
				min-width: 118px;
				color: white;
				font-size: 14px;
				text-align: center;
				padding: 5px 10px;
				vertical-align: 2px;
				border-radius: 100px;
				margin-left: 2em;
				@include xs {
					min-width: 100px;
					font-size: 13px;
					padding: 2px 5px;
					margin-left: 1em;
				}
			}
		}
		input {
			width: 100%;
			font-size: 30px;
			padding: 10px 15px;
			border: solid 6px #DEDDDE;
			@include xs {
				font-size: 20px;
				padding: 5px 10px;
			}
			&[type="submit"] {
				background-color: #B9293B;
				color: white;
				font-weight: bold;
				padding: 20px 15px;
				border: none;
				margin-top: 50px;
				@include xs {
					padding: 15px;
				}
			}
		}
		tr {
			margin-bottom: 35px;
			@include xs {
				margin-bottom: 25px;
			}
		}
		.swpm-profile-account-delete-section {
			margin-top: 50px;
			.swpm-account-delete-button {
				color: black !important;
				font-size: 24px;
				font-weight: bold;
				@include xs {
					font-size: 20px;
				}
			}
		}
	}
	&[data-level="2"] {
		#swpm-editprofile-form {
			label {
				&[for="user_name"]::after {
					content: "仮登録";
					background-color: #658E3B;
				}
			}
		}
	}
	&[data-level="3"] {
		#swpm-editprofile-form {
			label {
				&[for="user_name"]::after {
					content: "有料会員";
					background-color: #B9293B;
				}
			}
		}
	}

	section.privacy_policy,
	.act_on_specified_commercial_transactions {
		font-size: 16px;
		line-height: 2;
		h3, p {
			margin-bottom: 1.5em;
		}
		dl.clause {
			& > div {
				margin-bottom: 1.5em;
			}
			dt {
				font-weight: bold;
			}
			dd {
				ol {
					list-style: none;
					counter-reset: ol;
					padding-left: 2em;
					li {
						counter-increment: ol;
						p {
							margin-bottom: 0;
							&:first-of-type::before {
								content: counter(ol);
								display: inline-block;
								box-sizing: border-box;
								width: 2em;
								margin-left: -2em;
							}
						}
						&:nth-of-type(-n + 9) p:first-of-type::before {
							padding-left: .25em;
						}
					}
				}
			}
		}
	}
}