.page_col2 {
	#cols {
		display: flex;
		@include sm {
			display: block;
		}
		#col_main {
			flex: 1 1 0%;
			min-width: 0;
			article {
				margin-bottom: 100px;
				@include xs {
					margin-bottom: 80px;
				}
				.embed-container {
					position: relative; 
					padding-bottom: 56.25%;
					overflow: hidden;
					max-width: 100%;
					height: auto;
					margin-bottom: 50px;
					iframe, object, embed {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
				/* wordpress editor -------- */
				.editor {
					.aligncenter {
						display: block;
						margin: 0 auto;
						padding-bottom: 20px;
					}
					.alignright {
						float: right;
						padding-left: 20px;
						padding-bottom: 20px;
					}
					.alignleft {
						float: left;
						padding-right: 20px;
						padding-bottom: 20px;
					}
					img[class*="wp-image-"], img[class*="attachment-"] {
						max-width: 100%;
						height: auto;
					}
					a {
						color: #03c;
						text-decoration: none;
						&:hover {
							color: #69f;
						}
					}
					h1 {
						margin: 50px 0 40px;
						font-size: 175%;
						font-weight: bold;
					}
					h2 {
						margin: 40px 0 30px;
						font-size: 150%;
						font-weight: bold;
					}
					h3 {
						margin: 30px 0 20px;
						font-size: 125%;
						font-weight: bold;
					}
					p, ul, ol, table {
						font-size: 100%;
						line-height: 2em;
						margin-bottom: 20px;
					}
					li {
						margin-bottom: 10px;
					}
					ul {
						list-style-type: disc;
						padding-left: 1.5em;
					}
					ol {
						list-style-type: decimal;
						padding-left: 1.5em;
					}
					hr {
						margin: 20px 0;
						border: none;
						border-top: 1px solid #999;
					}
					table {
						width: 100%;
						border-right: 1px solid #b5b5b5;
						border-top: 1px solid #b5b5b5;
					}
					th {
						padding: 10px 20px;
						border-left: 1px solid #b5b5b5;
						border-bottom: 1px solid #b5b5b5;
						background-color: #eee;
					}
					td {
						padding: 10px 20px;
						border-left: 1px solid #b5b5b5;
						border-bottom: 1px solid #b5b5b5;
					}
					strong {
						font-weight: bold;
					}
					em {
						font-style: italic;
					}
					del {
						text-decoration: line-through;
					}
				}
			}
		}
		#col_side {
			width: 200px;
			margin-left: 40px;
			@include sm {
				width: auto;
				margin-left: 0;
			}
			dl.sidebar {
				margin-top: 50px;
				@include sm {
					margin-top: 120px;
				}
				& > div {
					margin-bottom: 100px;
					@include xs {
						margin-bottom: 80px;
					}
				}
				dt {
					font-size: 14px;
					font-weight: bold;
					margin-bottom: 30px;
					@include sm {
						font-size: 16px;
					}
					&::after {
						content: "　→";
					}
				}
				dd {
					ul {
						li {
							font-size: 13px;
							margin-bottom: 20px;
							@include sm {
								font-size: 16px;
							}
							a {
								display: block;
								transition: opacity .25s;
								&:hover {
									opacity: .75;
								}
							}
							.post_title {
								margin-bottom: 10px;
							}
							.post_thumbnail {

							}
						}
					}
				}
			}
		}
	}
}