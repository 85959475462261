@charset "UTF-8";
/* mixinsの読み込み */
/* variablesの読み込み */
/******************************************
* reset
******************************************/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

img, object, embed {
  max-width: 100%; }

html {
  overflow-y: scroll;
  font-size: 14px;
  line-height: 1.5; }

/******************************************
* font-size
******************************************/
ul {
  list-style: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

td, td img {
  vertical-align: top; }

.clickable, label, input[type=button], input[type=submit], input[type=file], button {
  cursor: pointer; }

button, input, select, textarea {
  margin: 0; }

button, input[type=button] {
  width: auto;
  overflow: visible; }

/******************************************
* ie11のみ適応
******************************************/
/******************************************
* clearfix
******************************************/
.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix .left {
  float: left; }

.clearfix .right {
  float: right; }

/******************************************
* wrapper
******************************************/
html {
  scroll-behavior: smooth; }

.container {
  padding: 0 10px;
  overflow: hidden; }

.over_visible {
  overflow: visible; }

.over_hidden {
  overflow: hidden; }

.wrapper {
  width: 100%;
  max-width: 940px;
  margin: auto; }
  @media screen and (min-width: 0) and (max-width: 767px) {
    .wrapper {
      width: auto; } }

/******************************************
* img
******************************************/
img {
  max-width: 100%;
  height: auto; }

/******************************************
* mt pd
******************************************/
.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.mt70 {
  margin-top: 70px !important; }

.mr70 {
  margin-right: 70px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.ml70 {
  margin-left: 70px !important; }

.pt70 {
  padding-top: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.mt75 {
  margin-top: 75px !important; }

.mr75 {
  margin-right: 75px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.ml75 {
  margin-left: 75px !important; }

.pt75 {
  padding-top: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.mt80 {
  margin-top: 80px !important; }

.mr80 {
  margin-right: 80px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.ml80 {
  margin-left: 80px !important; }

.pt80 {
  padding-top: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

.mt85 {
  margin-top: 85px !important; }

.mr85 {
  margin-right: 85px !important; }

.mb85 {
  margin-bottom: 85px !important; }

.ml85 {
  margin-left: 85px !important; }

.pt85 {
  padding-top: 85px !important; }

.pr85 {
  padding-right: 85px !important; }

.pb85 {
  padding-bottom: 85px !important; }

.pl85 {
  padding-left: 85px !important; }

.mt90 {
  margin-top: 90px !important; }

.mr90 {
  margin-right: 90px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.ml90 {
  margin-left: 90px !important; }

.pt90 {
  padding-top: 90px !important; }

.pr90 {
  padding-right: 90px !important; }

.pb90 {
  padding-bottom: 90px !important; }

.pl90 {
  padding-left: 90px !important; }

.mt95 {
  margin-top: 95px !important; }

.mr95 {
  margin-right: 95px !important; }

.mb95 {
  margin-bottom: 95px !important; }

.ml95 {
  margin-left: 95px !important; }

.pt95 {
  padding-top: 95px !important; }

.pr95 {
  padding-right: 95px !important; }

.pb95 {
  padding-bottom: 95px !important; }

.pl95 {
  padding-left: 95px !important; }

.mt100 {
  margin-top: 100px !important; }

.mr100 {
  margin-right: 100px !important; }

.mb100 {
  margin-bottom: 100px !important; }

.ml100 {
  margin-left: 100px !important; }

.pt100 {
  padding-top: 100px !important; }

.pr100 {
  padding-right: 100px !important; }

.pb100 {
  padding-bottom: 100px !important; }

.pl100 {
  padding-left: 100px !important; }

.mt105 {
  margin-top: 105px !important; }

.mr105 {
  margin-right: 105px !important; }

.mb105 {
  margin-bottom: 105px !important; }

.ml105 {
  margin-left: 105px !important; }

.pt105 {
  padding-top: 105px !important; }

.pr105 {
  padding-right: 105px !important; }

.pb105 {
  padding-bottom: 105px !important; }

.pl105 {
  padding-left: 105px !important; }

.mt110 {
  margin-top: 110px !important; }

.mr110 {
  margin-right: 110px !important; }

.mb110 {
  margin-bottom: 110px !important; }

.ml110 {
  margin-left: 110px !important; }

.pt110 {
  padding-top: 110px !important; }

.pr110 {
  padding-right: 110px !important; }

.pb110 {
  padding-bottom: 110px !important; }

.pl110 {
  padding-left: 110px !important; }

.mt115 {
  margin-top: 115px !important; }

.mr115 {
  margin-right: 115px !important; }

.mb115 {
  margin-bottom: 115px !important; }

.ml115 {
  margin-left: 115px !important; }

.pt115 {
  padding-top: 115px !important; }

.pr115 {
  padding-right: 115px !important; }

.pb115 {
  padding-bottom: 115px !important; }

.pl115 {
  padding-left: 115px !important; }

.mt120 {
  margin-top: 120px !important; }

.mr120 {
  margin-right: 120px !important; }

.mb120 {
  margin-bottom: 120px !important; }

.ml120 {
  margin-left: 120px !important; }

.pt120 {
  padding-top: 120px !important; }

.pr120 {
  padding-right: 120px !important; }

.pb120 {
  padding-bottom: 120px !important; }

.pl120 {
  padding-left: 120px !important; }

.mt125 {
  margin-top: 125px !important; }

.mr125 {
  margin-right: 125px !important; }

.mb125 {
  margin-bottom: 125px !important; }

.ml125 {
  margin-left: 125px !important; }

.pt125 {
  padding-top: 125px !important; }

.pr125 {
  padding-right: 125px !important; }

.pb125 {
  padding-bottom: 125px !important; }

.pl125 {
  padding-left: 125px !important; }

.mt130 {
  margin-top: 130px !important; }

.mr130 {
  margin-right: 130px !important; }

.mb130 {
  margin-bottom: 130px !important; }

.ml130 {
  margin-left: 130px !important; }

.pt130 {
  padding-top: 130px !important; }

.pr130 {
  padding-right: 130px !important; }

.pb130 {
  padding-bottom: 130px !important; }

.pl130 {
  padding-left: 130px !important; }

.mt135 {
  margin-top: 135px !important; }

.mr135 {
  margin-right: 135px !important; }

.mb135 {
  margin-bottom: 135px !important; }

.ml135 {
  margin-left: 135px !important; }

.pt135 {
  padding-top: 135px !important; }

.pr135 {
  padding-right: 135px !important; }

.pb135 {
  padding-bottom: 135px !important; }

.pl135 {
  padding-left: 135px !important; }

.mt140 {
  margin-top: 140px !important; }

.mr140 {
  margin-right: 140px !important; }

.mb140 {
  margin-bottom: 140px !important; }

.ml140 {
  margin-left: 140px !important; }

.pt140 {
  padding-top: 140px !important; }

.pr140 {
  padding-right: 140px !important; }

.pb140 {
  padding-bottom: 140px !important; }

.pl140 {
  padding-left: 140px !important; }

.mt145 {
  margin-top: 145px !important; }

.mr145 {
  margin-right: 145px !important; }

.mb145 {
  margin-bottom: 145px !important; }

.ml145 {
  margin-left: 145px !important; }

.pt145 {
  padding-top: 145px !important; }

.pr145 {
  padding-right: 145px !important; }

.pb145 {
  padding-bottom: 145px !important; }

.pl145 {
  padding-left: 145px !important; }

.mt150 {
  margin-top: 150px !important; }

.mr150 {
  margin-right: 150px !important; }

.mb150 {
  margin-bottom: 150px !important; }

.ml150 {
  margin-left: 150px !important; }

.pt150 {
  padding-top: 150px !important; }

.pr150 {
  padding-right: 150px !important; }

.pb150 {
  padding-bottom: 150px !important; }

.pl150 {
  padding-left: 150px !important; }

.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mt-5 {
  margin-top: -5px !important; }

.mr-5 {
  margin-right: -5px !important; }

.mb-5 {
  margin-bottom: -5px !important; }

.ml-5 {
  margin-left: -5px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mr-10 {
  margin-right: -10px !important; }

.mb-10 {
  margin-bottom: -10px !important; }

.ml-10 {
  margin-left: -10px !important; }

.mt-15 {
  margin-top: -15px !important; }

.mr-15 {
  margin-right: -15px !important; }

.mb-15 {
  margin-bottom: -15px !important; }

.ml-15 {
  margin-left: -15px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mr-20 {
  margin-right: -20px !important; }

.mb-20 {
  margin-bottom: -20px !important; }

.ml-20 {
  margin-left: -20px !important; }

.mt-25 {
  margin-top: -25px !important; }

.mr-25 {
  margin-right: -25px !important; }

.mb-25 {
  margin-bottom: -25px !important; }

.ml-25 {
  margin-left: -25px !important; }

.mt-30 {
  margin-top: -30px !important; }

.mr-30 {
  margin-right: -30px !important; }

.mb-30 {
  margin-bottom: -30px !important; }

.ml-30 {
  margin-left: -30px !important; }

.mt-35 {
  margin-top: -35px !important; }

.mr-35 {
  margin-right: -35px !important; }

.mb-35 {
  margin-bottom: -35px !important; }

.ml-35 {
  margin-left: -35px !important; }

.mt-40 {
  margin-top: -40px !important; }

.mr-40 {
  margin-right: -40px !important; }

.mb-40 {
  margin-bottom: -40px !important; }

.ml-40 {
  margin-left: -40px !important; }

.mt-45 {
  margin-top: -45px !important; }

.mr-45 {
  margin-right: -45px !important; }

.mb-45 {
  margin-bottom: -45px !important; }

.ml-45 {
  margin-left: -45px !important; }

.mt-50 {
  margin-top: -50px !important; }

.mr-50 {
  margin-right: -50px !important; }

.mb-50 {
  margin-bottom: -50px !important; }

.ml-50 {
  margin-left: -50px !important; }

.mt-55 {
  margin-top: -55px !important; }

.mr-55 {
  margin-right: -55px !important; }

.mb-55 {
  margin-bottom: -55px !important; }

.ml-55 {
  margin-left: -55px !important; }

.mt-60 {
  margin-top: -60px !important; }

.mr-60 {
  margin-right: -60px !important; }

.mb-60 {
  margin-bottom: -60px !important; }

.ml-60 {
  margin-left: -60px !important; }

.mt-65 {
  margin-top: -65px !important; }

.mr-65 {
  margin-right: -65px !important; }

.mb-65 {
  margin-bottom: -65px !important; }

.ml-65 {
  margin-left: -65px !important; }

.mt-70 {
  margin-top: -70px !important; }

.mr-70 {
  margin-right: -70px !important; }

.mb-70 {
  margin-bottom: -70px !important; }

.ml-70 {
  margin-left: -70px !important; }

.mt-75 {
  margin-top: -75px !important; }

.mr-75 {
  margin-right: -75px !important; }

.mb-75 {
  margin-bottom: -75px !important; }

.ml-75 {
  margin-left: -75px !important; }

.mt-80 {
  margin-top: -80px !important; }

.mr-80 {
  margin-right: -80px !important; }

.mb-80 {
  margin-bottom: -80px !important; }

.ml-80 {
  margin-left: -80px !important; }

.mt-85 {
  margin-top: -85px !important; }

.mr-85 {
  margin-right: -85px !important; }

.mb-85 {
  margin-bottom: -85px !important; }

.ml-85 {
  margin-left: -85px !important; }

.mt-90 {
  margin-top: -90px !important; }

.mr-90 {
  margin-right: -90px !important; }

.mb-90 {
  margin-bottom: -90px !important; }

.ml-90 {
  margin-left: -90px !important; }

.mt-95 {
  margin-top: -95px !important; }

.mr-95 {
  margin-right: -95px !important; }

.mb-95 {
  margin-bottom: -95px !important; }

.ml-95 {
  margin-left: -95px !important; }

.mt-100 {
  margin-top: -100px !important; }

.mr-100 {
  margin-right: -100px !important; }

.mb-100 {
  margin-bottom: -100px !important; }

.ml-100 {
  margin-left: -100px !important; }

/******************************************
* common-style
******************************************/
* {
  box-sizing: border-box; }

body {
  background-color: white;
  color: #202124;
  font-size: 16px; }

#main {
  padding: 40px 0 150px; }
  @media screen and (max-width: 575px) {
    #main {
      padding: 30px 0 120px; } }

.breadcrumbs {
  list-style: none;
  display: flex;
  font-size: 13px;
  margin-bottom: 80px; }
  @media screen and (max-width: 575px) {
    .breadcrumbs {
      margin-bottom: 20px; } }
  .breadcrumbs li + li::before {
    content: '>';
    margin: 0 .5em; }

.accent {
  color: #E60012; }

.tac {
  text-align: center; }

.tar {
  text-align: right; }

.tal {
  text-align: left; }

.d_table {
  display: table; }
  .d_table.full_width {
    width: 100%; }
  .d_table.layout_fixed {
    table-layout: fixed; }
  .d_table .d_cell {
    display: table-cell;
    vertical-align: top; }
    .d_table .d_cell.v_middle {
      vertical-align: middle; }

.text_danger {
  color: #AB322E; }

.ws-pl {
  white-space: pre-line; }

.hov_opacify {
  transition: opacity .25s; }
  .hov_opacify:hover {
    opacity: .75; }

.radius_btn {
  display: inline-block;
  min-width: 118px;
  background-color: #B9293B;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 100px; }
  .radius_btn_green {
    background-color: #658E3B; }

dl.archive {
  font-size: 16px;
  margin-bottom: 70px; }
  dl.archive div {
    display: flex;
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      dl.archive div {
        display: block;
        margin-bottom: 20px; } }
  dl.archive dt {
    min-width: 8em;
    font-weight: bold;
    margin-right: 2.5em; }
    @media screen and (max-width: 767px) {
      dl.archive dt {
        margin-bottom: 5px; } }
  dl.archive dd .cat_tag {
    display: inline-block;
    background-color: #31475E;
    color: white;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 5px;
    margin-right: 5px;
    vertical-align: 2px; }
  @media screen and (max-width: 575px) {
    dl.archive dd .info {
      display: block;
      margin-top: 3px; } }

.title_red {
  margin-bottom: 60px; }
  @media screen and (max-width: 575px) {
    .title_red {
      margin-bottom: 40px; } }
  .title_red::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(to right, #F92525 30px, rgba(32, 33, 36, 0.1) 30px, rgba(32, 33, 36, 0.1));
    margin-top: 10px; }
    @media screen and (max-width: 575px) {
      .title_red::after {
        margin-top: 5px; } }

.title_main {
  font-size: 32px;
  line-height: 1.25; }
  @media screen and (max-width: 575px) {
    .title_main {
      font-size: 26px; } }

.title_article {
  font-size: 21px;
  margin-bottom: 30px; }

.grid_menu .grid {
  min-height: 320px;
  color: inherit;
  border-radius: 3px;
  box-shadow: 0 8px 20px rgba(224, 224, 224, 0.5);
  overflow: hidden; }
  @media screen and (max-width: 991px) {
    .grid_menu .grid {
      min-height: 0; } }
  .grid_menu .grid .thumb {
    overflow: hidden; }
    .grid_menu .grid .thumb img {
      display: block;
      width: 100%;
      transition: transform .25s; }
  .grid_menu .grid:hover .thumb img {
    transform: scale(1.05); }
  .grid_menu .grid dl {
    padding: 20px 30px; }
    @media screen and (max-width: 767px) {
      .grid_menu .grid dl {
        padding: 15px 20px; } }
    @media screen and (max-width: 575px) {
      .grid_menu .grid dl {
        padding: 20px 20px; } }
    .grid_menu .grid dl dt {
      color: #31475E;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px; }
    .grid_menu .grid dl dd {
      font-size: 16px; }
      @media screen and (max-width: 991px) {
        .grid_menu .grid dl dd {
          text-align: justify; }
          .grid_menu .grid dl dd br {
            display: none; } }

.pagetop {
  margin: 40px 0; }
  .pagetop img {
    display: block;
    margin: 0 auto; }

.navigation.pagination[role="navigation"] {
  margin-bottom: 70px; }
  .navigation.pagination[role="navigation"] .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden; }

dl.archive + .navigation.pagination[role="navigation"] {
  margin-top: -30px; }

.col5 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }
  .col5 > * {
    width: calc(100% / 5 - 5px * 2 - 1px);
    max-width: calc(100% / 5 - 5px * 2);
    flex: 1 0 auto;
    margin: 0 5px 10px; }
  @media screen and (max-width: 1199px) {
    .col5 {
      margin: 0 -10px; }
      .col5 > * {
        width: calc(100% / 4 - 10px * 2 - 1px);
        max-width: calc(100% / 4 - 10px * 2);
        margin: 0 10px 20px; } }
  @media screen and (max-width: 991px) {
    .col5 > * {
      width: calc(100% / 3 - 10px * 2 - 1px);
      max-width: calc(100% / 3 - 10px * 2);
      margin: 0 10px 20px; } }
  @media screen and (max-width: 767px) {
    .col5 > * {
      width: calc(100% / 2 - 10px * 2 - 1px);
      max-width: calc(100% / 2 - 10px * 2);
      margin: 0 10px 20px; } }
  @media screen and (max-width: 575px) {
    .col5 {
      margin: 0; }
      .col5 > * {
        width: 100%;
        max-width: none;
        margin: 0 0 20px; } }

.col4 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  .col4 > * {
    width: calc(100% / 4 - 10px * 2 - 1px);
    max-width: calc(100% / 4 - 10px * 2);
    flex: 1 0 auto;
    margin: 0 10px 20px; }
  @media screen and (max-width: 991px) {
    .col4 > * {
      width: calc(100% / 3 - 10px * 2 - 1px);
      max-width: calc(100% / 3 - 10px * 2);
      margin: 0 10px 20px; } }
  @media screen and (max-width: 767px) {
    .col4 > * {
      width: calc(100% / 2 - 10px * 2 - 1px);
      max-width: calc(100% / 2 - 10px * 2);
      margin: 0 10px 20px; } }
  @media screen and (max-width: 575px) {
    .col4 {
      margin: 0; }
      .col4 > * {
        width: 100%;
        max-width: none;
        margin: 0 0 20px; } }

.col3 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  .col3 > * {
    width: calc(100% / 3 - 10px * 2 - 1px);
    max-width: calc(100% / 3 - 10px * 2);
    flex: 1 0 auto;
    margin: 0 10px 20px; }
  @media screen and (max-width: 767px) {
    .col3 > * {
      width: calc(100% / 2 - 10px * 2 - 1px);
      max-width: calc(100% / 2 - 10px * 2);
      margin: 0 10px 20px; } }
  @media screen and (max-width: 575px) {
    .col3 {
      margin: 0; }
      .col3 > * {
        width: 100%;
        max-width: none;
        margin: 0 0 20px; } }

.col2 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .col2 > * {
    width: calc(100% / 2 - 15px * 2 - 1px);
    max-width: calc(100% / 2 - 15px * 2);
    flex: 1 0 auto;
    margin: 0 15px 20px; }
  @media screen and (max-width: 991px) {
    .col2 {
      margin: 0 -10px; }
      .col2 > * {
        width: calc(100% / 2 - 10px * 2 - 1px);
        max-width: calc(100% / 2 - 10px * 2);
        margin: 0 10px 20px; } }
  @media screen and (max-width: 575px) {
    .col2 {
      margin: 0; }
      .col2 > * {
        width: 100%;
        max-width: none;
        margin: 0 0 20px; } }

.col2m {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px; }
  .col2m > * {
    width: calc(100% / 2 - 25px * 2 - 1px);
    max-width: calc(100% / 2 - 25px * 2);
    flex: 1 0 auto;
    margin: 0 25px 20px; }
  @media screen and (max-width: 1199px) {
    .col2m {
      margin: 0 -15px; }
      .col2m > * {
        width: calc(100% / 2 - 15px * 2 - 1px);
        max-width: calc(100% / 2 - 15px * 2);
        flex: 1 0 auto;
        margin: 0 15px 20px; } }
  @media screen and (max-width: 991px) {
    .col2m {
      margin: 0 -10px; }
      .col2m > * {
        width: calc(100% / 2 - 10px * 2 - 1px);
        max-width: calc(100% / 2 - 10px * 2);
        margin: 0 10px 20px; } }
  @media screen and (max-width: 575px) {
    .col2m {
      margin: 0; }
      .col2m > * {
        width: 100%;
        max-width: none;
        margin: 0 0 20px; } }

/* lg */
@media screen and (max-width: 1199px) {
  .col4lg {
    margin: 0 -10px; }
    .col4lg > * {
      width: calc(100% / 4 - 10px * 2 - 1px);
      max-width: calc(100% / 4 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 1199px) {
  .col3lg {
    margin: 0 -10px; }
    .col3lg > * {
      width: calc(100% / 3 - 10px * 2 - 1px);
      max-width: calc(100% / 3 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 1199px) {
  .col2lg {
    margin: 0 -15px; }
    .col2lg > * {
      width: calc(100% / 2 - 15px * 2 - 1px);
      max-width: calc(100% / 2 - 15px * 2);
      margin: 0 15px 20px; } }

@media screen and (max-width: 991px) {
  .col2lg {
    margin: 0 -10px; }
    .col2lg > * {
      width: calc(100% / 2 - 10px * 2 - 1px);
      max-width: calc(100% / 2 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 1199px) {
  .col1lg {
    margin: 0; }
    .col1lg > * {
      width: 100%;
      max-width: none;
      margin: 0 0 20px; } }

/* md */
@media screen and (max-width: 991px) {
  .col4md {
    margin: 0 -10px; }
    .col4md > * {
      width: calc(100% / 4 - 10px * 2 - 1px);
      max-width: calc(100% / 4 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 991px) {
  .col3md {
    margin: 0 -10px; }
    .col3md > * {
      width: calc(100% / 3 - 10px * 2 - 1px);
      max-width: calc(100% / 3 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 991px) {
  .col2md {
    margin: 0 -10px; }
    .col2md > * {
      width: calc(100% / 2 - 10px * 2 - 1px);
      max-width: calc(100% / 2 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 991px) {
  .col1md {
    margin: 0; }
    .col1md > * {
      width: 100%;
      max-width: none;
      margin: 0 0 20px; } }

/* sm */
@media screen and (max-width: 767px) {
  .col3sm {
    margin: 0 -10px; }
    .col3sm > * {
      width: calc(100% / 3 - 10px * 2 - 1px);
      max-width: calc(100% / 3 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 767px) {
  .col2sm {
    margin: 0 -10px; }
    .col2sm > * {
      width: calc(100% / 2 - 10px * 2 - 1px);
      max-width: calc(100% / 2 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 767px) {
  .col1sm {
    margin: 0; }
    .col1sm > * {
      width: 100%;
      max-width: none;
      margin: 0 0 20px; } }

/* xs */
@media screen and (max-width: 575px) {
  .col2xs {
    margin: 0 -10px; }
    .col2xs > * {
      width: calc(100% / 2 - 10px * 2 - 1px);
      max-width: calc(100% / 2 - 10px * 2);
      margin: 0 10px 20px; } }

@media screen and (max-width: 575px) {
  .col1xs {
    margin: 0; }
    .col1xs > * {
      width: 100%;
      max-width: none;
      margin: 0 0 20px; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slide-downwards {
  from {
    transform: translateY(-100%); }
  to {
    transform: none; } }

@keyframes focus {
  from {
    filter: blur(10px); }
  to {
    filter: none; } }

/* vendor */
/* parts */
#header {
  height: 167px;
  background-color: #1b0b02;
  background-repeat: no-repeat; }
  #header.header_page {
    background-size: auto 100%;
    background-position: center;
    background-image: url(../images/header.jpg); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      #header.header_page {
        background-image: url(../images/header@2x.jpg); } }
    @media screen and (min-width: 1568px) {
      #header.header_page {
        background-size: 90% auto;
        background-position: center left; } }
    @media screen and (max-width: 1199px) {
      #header.header_page {
        background-position: center right 40%; } }
    @media screen and (max-width: 767px) {
      #header.header_page {
        background-position: center left; } }
    @media screen and (max-width: 575px) {
      #header.header_page {
        height: 100px;
        background-position: bottom right 30%; } }
  #header.header_home, #header.header_login {
    background-size: cover;
    background-image: url(../images/header_lg.jpg); }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      #header.header_home, #header.header_login {
        background-image: url(../images/header_lg@2x.jpg); } }
    #header.header_home .title, #header.header_login .title {
      width: 62.340425531914894%; }
  #header.header_home {
    height: 42.796005706134094vw;
    min-height: 400px;
    max-height: 600px;
    background-position: bottom 20% right 48%; }
    @media screen and (max-width: 575px) {
      #header.header_home {
        height: 200px;
        min-height: 0;
        background-position: bottom right 30%; } }
    #header.header_home .title {
      position: absolute;
      top: 37%;
      min-width: 370px; }
      @media screen and (max-width: 575px) {
        #header.header_home .title {
          display: none; } }
  #header.header_login {
    height: auto;
    background-position: bottom right 34%; }
    @media screen and (max-width: 575px) {
      #header.header_login {
        background-position: top left;
        background-size: auto 47.885075818036712vw;
        padding-top: 47.885075818036712vw; } }
    #header.header_login .wrapper {
      padding-top: 67px;
      padding-bottom: 50px; }
      @media screen and (max-width: 575px) {
        #header.header_login .wrapper {
          position: static;
          padding: 0 0 20px; } }
    #header.header_login .title {
      margin-bottom: 50px; }
      @media screen and (max-width: 575px) {
        #header.header_login .title {
          position: absolute;
          top: 23.942537909018356vw;
          transform: translateY(-50%); } }
      #header.header_login .title img {
        display: block; }
    #header.header_login .login_form {
      display: flex;
      flex-direction: column;
      width: 56.276595744680851%;
      background-color: rgba(255, 255, 255, 0.47);
      color: white;
      padding: 15px 60px 30px;
      margin-bottom: 20px; }
      @media screen and (max-width: 991px) {
        #header.header_login .login_form {
          padding: 15px 30px 30px; } }
      @media screen and (max-width: 575px) {
        #header.header_login .login_form {
          width: auto;
          padding: 15px 23px 20px;
          margin-bottom: 0; } }
      #header.header_login .login_form h2 {
        font-size: 24px;
        margin-bottom: 10px; }
        @media screen and (max-width: 575px) {
          #header.header_login .login_form h2 {
            font-size: 18px; } }
      #header.header_login .login_form .swpm-login-form-inner {
        display: flex;
        flex-direction: column; }
        #header.header_login .login_form .swpm-login-form-inner .swpm-username-input,
        #header.header_login .login_form .swpm-login-form-inner .swpm-password-input {
          margin-bottom: 10px; }
          #header.header_login .login_form .swpm-login-form-inner .swpm-username-input input,
          #header.header_login .login_form .swpm-login-form-inner .swpm-password-input input {
            width: 100%;
            font-size: 14px;
            padding: 10px; }
            @media screen and (max-width: 575px) {
              #header.header_login .login_form .swpm-login-form-inner .swpm-username-input input,
              #header.header_login .login_form .swpm-login-form-inner .swpm-password-input input {
                padding: 5px 10px; } }
        #header.header_login .login_form .swpm-login-form-inner .swpm-remember-me {
          margin-bottom: 5px; }
          #header.header_login .login_form .swpm-login-form-inner .swpm-remember-me .swpm-remember-checkbox,
          #header.header_login .login_form .swpm-login-form-inner .swpm-remember-me .swpm-rember-label {
            font-size: 12px;
            vertical-align: middle; }
            @media screen and (max-width: 575px) {
              #header.header_login .login_form .swpm-login-form-inner .swpm-remember-me .swpm-remember-checkbox,
              #header.header_login .login_form .swpm-login-form-inner .swpm-remember-me .swpm-rember-label {
                font-size: 14px; } }
        #header.header_login .login_form .swpm-login-form-inner .swpm-login-submit .swpm-login-form-submit,
        #header.header_login .login_form .swpm-login-form-inner .swpm-login-submit .swpm-login-form-register-link,
        #header.header_login .login_form .swpm-login-form-inner .swpm-join-us-link .swpm-login-form-submit,
        #header.header_login .login_form .swpm-login-form-inner .swpm-join-us-link .swpm-login-form-register-link {
          display: block;
          width: 100%;
          background: #658E3B;
          color: white;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          line-height: 1.5;
          padding: 8px 15px;
          border: none; }
        #header.header_login .login_form .swpm-login-form-inner .swpm-login-submit .swpm-login-form-register-link,
        #header.header_login .login_form .swpm-login-form-inner .swpm-join-us-link .swpm-login-form-register-link {
          background-color: #B9293B; }
        #header.header_login .login_form .swpm-login-form-inner .swpm-forgot-pass-link {
          font-size: 12px;
          margin-bottom: 10px; }
          @media screen and (max-width: 575px) {
            #header.header_login .login_form .swpm-login-form-inner .swpm-forgot-pass-link {
              font-size: 14px; } }
          #header.header_login .login_form .swpm-login-form-inner .swpm-forgot-pass-link .swpm-login-form-pw-reset-link {
            color: inherit; }
        #header.header_login .login_form .swpm-login-form-inner .swpm-login-action-msg {
          margin-bottom: 10px; }
          #header.header_login .login_form .swpm-login-form-inner .swpm-login-action-msg .swpm-login-widget-action-msg {
            color: crimson;
            font-weight: normal;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25); }
        #header.header_login .login_form .swpm-login-form-inner .swpm-username-label,
        #header.header_login .login_form .swpm-login-form-inner .swpm-username-input,
        #header.header_login .login_form .swpm-login-form-inner .swpm-password-label,
        #header.header_login .login_form .swpm-login-form-inner .swpm-password-input {
          order: 1; }
        #header.header_login .login_form .swpm-login-form-inner .swpm-remember-me,
        #header.header_login .login_form .swpm-login-form-inner .swpm-forgot-pass-link {
          order: 2; }
        #header.header_login .login_form .swpm-login-form-inner .swpm-before-login-submit-section,
        #header.header_login .login_form .swpm-login-form-inner .swpm-login-submit,
        #header.header_login .login_form .swpm-login-form-inner .swpm-join-us-link {
          order: 3; }
  #header .container, #header .wrapper {
    height: inherit;
    min-height: inherit;
    max-height: inherit; }
  #header .wrapper {
    position: relative;
    padding-top: 35px; }
    @media screen and (max-width: 575px) {
      #header .wrapper {
        padding-top: 30px; } }
  #header .logo {
    display: inline-block;
    vertical-align: top; }
    @media screen and (max-width: 575px) {
      #header .logo {
        max-width: 68%;
        margin-top: -3px; } }
  #header .radius_btn {
    vertical-align: top;
    margin-right: 5px; }
    @media screen and (max-width: 575px) {
      #header .radius_btn {
        display: none; } }

#menu {
  position: absolute;
  width: 328px;
  top: 35px;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 2147483647;
  transition: visibility .25s step-end, opacity .25s; }
  @media screen and (max-width: 575px) {
    #menu {
      top: 30px;
      max-width: 300px; } }
  #menu.show {
    visibility: visible;
    opacity: 1;
    transition: visibility .25s step-start, opacity .25s; }
  #menu ul {
    color: white;
    font-size: 20px; }
    @media screen and (max-width: 575px) {
      #menu ul {
        font-size: 16px; } }
    #menu ul li {
      background-color: rgba(65, 117, 5, 0.84);
      border-color: #417505;
      border-style: none solid solid;
      border-width: 1px;
      transition: background-color .25s; }
      #menu ul li:not(:first-of-type):hover {
        background-color: rgba(73, 132, 6, 0.84); }
      @media screen and (max-width: 575px) {
        #menu ul li {
          background-color: rgba(65, 117, 5, 0.9); }
          #menu ul li:not(:first-of-type):hover {
            background-color: rgba(73, 132, 6, 0.9); } }
      #menu ul li:first-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        border-top-style: solid; }
        #menu ul li:first-of-type #close_menu {
          position: relative;
          width: 50px;
          height: 50px;
          background: none;
          padding: 0;
          border: none; }
          @media screen and (max-width: 575px) {
            #menu ul li:first-of-type #close_menu {
              width: 44px;
              height: 44px; } }
          #menu ul li:first-of-type #close_menu::before, #menu ul li:first-of-type #close_menu::after {
            content: "";
            position: absolute;
            top: 0;
            left: 5px;
            bottom: 0;
            width: 2px;
            height: 20px;
            background-color: white;
            margin: auto 0; }
          #menu ul li:first-of-type #close_menu::before {
            transform: rotate(45deg); }
          #menu ul li:first-of-type #close_menu::after {
            transform: rotate(135deg); }
        #menu ul li:first-of-type span {
          font-size: 14px; }
      #menu ul li.for_xs {
        display: none; }
        @media screen and (max-width: 575px) {
          #menu ul li.for_xs {
            display: block; } }
      #menu ul li a {
        color: inherit;
        display: block;
        padding: 10px 20px; }
        @media screen and (max-width: 575px) {
          #menu ul li a {
            padding: 10px 15px; } }

#open_menu {
  position: absolute;
  right: 0;
  width: 67px;
  background: transparent;
  padding: 0;
  border: none;
  margin: 0; }
  @media screen and (max-width: 575px) {
    #open_menu {
      width: 48px; } }
  #open_menu .hamburger {
    position: relative;
    height: 10px;
    background-color: white;
    margin: 40px 0 3px; }
    #open_menu .hamburger::before, #open_menu .hamburger::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 10px;
      background-color: white; }
    #open_menu .hamburger::before {
      top: -40px; }
    #open_menu .hamburger::after {
      top: -20px; }
    @media screen and (max-width: 575px) {
      #open_menu .hamburger {
        height: 7px;
        margin: 28px 0 2px; }
        #open_menu .hamburger::before, #open_menu .hamburger::after {
          height: 7px; }
        #open_menu .hamburger::before {
          top: -28px; }
        #open_menu .hamburger::after {
          top: -14px; } }
  #open_menu p {
    color: white;
    text-align: center;
    font-size: 14px; }
    @media screen and (max-width: 575px) {
      #open_menu p {
        font-size: 12px; } }

#footer {
  background-color: #020202;
  color: white;
  height: 207px;
  background-image: url(../images/footer.jpg);
  background-repeat: no-repeat;
  background-size: auto 207px;
  background-position: center; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    #footer {
      background-image: url(../images/footer@2x.jpg); } }
  @media screen and (max-width: 991px) {
    #footer {
      background-position: left; } }
  @media screen and (max-width: 767px) {
    #footer {
      background-size: auto 150px;
      background-position: left bottom;
      height: 150px; } }
  @media screen and (max-width: 575px) {
    #footer {
      background-size: auto 110px; } }
  #footer .container,
  #footer .wrapper {
    height: inherit; }
  #footer .wrapper {
    position: relative; }
    #footer .wrapper .logo {
      position: absolute;
      top: 50px;
      left: 180px; }
      @media screen and (max-width: 767px) {
        #footer .wrapper .logo {
          top: 30px;
          left: 120px; } }
      @media screen and (max-width: 575px) {
        #footer .wrapper .logo {
          top: 20px;
          left: 10px; } }
    #footer .wrapper .links {
      position: absolute;
      top: 50px;
      right: 0;
      font-size: 12px;
      text-align: right; }
      @media screen and (max-width: 767px) {
        #footer .wrapper .links {
          top: 30px; } }
      @media screen and (max-width: 575px) {
        #footer .wrapper .links {
          top: 30px; } }
      #footer .wrapper .links a {
        color: inherit; }
        @media screen and (max-width: 767px) {
          #footer .wrapper .links a {
            display: block;
            margin-bottom: 10px; } }
        #footer .wrapper .links a + a {
          margin-left: 2em; }
          @media screen and (max-width: 767px) {
            #footer .wrapper .links a + a {
              margin-left: 0; } }
    #footer .wrapper .copyright {
      position: absolute;
      bottom: 30px;
      right: 0;
      font-size: 10px; }
      @media screen and (max-width: 767px) {
        #footer .wrapper .copyright {
          bottom: 20px; } }
      @media screen and (max-width: 575px) {
        #footer .wrapper .copyright {
          bottom: 15px; } }

/* page */
.page_test h2 {
  counter-reset: dummy; }

.page_test .dummy {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gainsboro;
  min-height: 50px;
  counter-increment: dummy; }
  .page_test .dummy::after {
    content: counter(dummy); }

.page_home p.welcome {
  color: #B9293B;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px; }
  @media screen and (max-width: 575px) {
    .page_home p.welcome {
      margin-bottom: 40px; } }

.page_home .btn_to_payment {
  display: block;
  font-size: 30px;
  background-color: #B9293B;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 20px 15px;
  border: none;
  margin-top: 50px; }
  @media screen and (max-width: 575px) {
    .page_home .btn_to_payment {
      font-size: 20px;
      padding: 15px; } }

.page_home section.whats_news {
  margin-bottom: 100px; }
  .page_home section.whats_news .title_red img, .page_home section.whats_news .title_red .ja {
    vertical-align: middle; }
  .page_home section.whats_news .title_red .ja {
    font-size: 18px;
    font-weight: normal;
    margin-left: 1em; }
  .page_home section.whats_news .archive_btn {
    display: block;
    max-width: 240px;
    background-color: black;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 12px 20px;
    margin-left: auto; }

.page_login section.greetings {
  margin-bottom: 200px; }
  .page_login section.greetings h2 {
    font-size: 22px;
    margin-bottom: 30px; }
  .page_login section.greetings p {
    font-size: 16px;
    line-height: 2;
    margin-bottom: 1.25em; }
    .page_login section.greetings p.ps {
      margin-top: 4em; }
  @media screen and (max-width: 1199px) {
    .page_login section.greetings {
      text-align: left; }
      .page_login section.greetings br.wbr {
        display: none; } }
  @media screen and (max-width: 767px) {
    .page_login section.greetings br {
      display: none; } }

.page_login section.special_favor {
  margin-bottom: 100px; }
  .page_login section.special_favor h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px; }
  .page_login section.special_favor .descripton {
    text-align: center;
    margin-bottom: 20px; }

.page_login section.entry_condition {
  text-align: center; }
  .page_login section.entry_condition h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px; }
  .page_login section.entry_condition .block {
    display: inline-block;
    text-align: left;
    background-color: #F4F3EE;
    padding: 20px 30px; }
    .page_login section.entry_condition .block ul {
      list-style: disc;
      padding-left: 1.25em; }
      .page_login section.entry_condition .block ul li {
        margin-bottom: 10px; }
  .page_login section.entry_condition .btn_wrap {
    text-align: center;
    margin-top: 30px; }
    .page_login section.entry_condition .btn_wrap .btn_entry {
      display: inline-block;
      background-color: #B9293B;
      color: white;
      font-weight: bold;
      padding: 20px 15px;
      border: none;
      margin-top: 50px;
      width: 100%;
      font-size: 30px;
      cursor: pointer; }
      .page_login section.entry_condition .btn_wrap .btn_entry .f_small {
        font-size: 80%; }

.page_register .breadcrumbs {
  margin-bottom: 25px; }
  @media screen and (max-width: 575px) {
    .page_register .breadcrumbs {
      margin-bottom: 20px; } }

.page_register #message.error {
  color: #B9293B;
  background: lavenderblush;
  padding: 20px 30px;
  margin-bottom: 30px; }
  @media screen and (max-width: 575px) {
    .page_register #message.error {
      padding: 15px 10px; } }
  .page_register #message.error ul {
    list-style: disc;
    padding-left: 1.5em;
    margin-top: 1em; }
    @media screen and (max-width: 575px) {
      .page_register #message.error ul {
        padding-left: 1.25em; } }

.page_register .steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  list-style: none;
  margin-bottom: 50px; }
  .page_register .steps li {
    display: table-cell;
    position: relative;
    height: 50px;
    background-color: #658E3B;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    padding-right: 15px; }
    @media screen and (max-width: 575px) {
      .page_register .steps li {
        height: 40px;
        font-size: 18px; } }
    .page_register .steps li.curr {
      background-color: #B9293B; }
    .page_register .steps li::before, .page_register .steps li::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 15px;
      right: -2px; }
      @media screen and (max-width: 575px) {
        .page_register .steps li::before, .page_register .steps li::after {
          border-width: 12px; } }
    .page_register .steps li::before {
      top: -1px;
      border-color: white white transparent transparent; }
    .page_register .steps li::after {
      bottom: -1px;
      border-color: transparent white white transparent; }

.page_register .text p {
  margin-bottom: 1.5em; }

.page_register .text.notes {
  background-color: #F4F3EE;
  padding: 20px 30px;
  margin-bottom: 60px; }
  @media screen and (max-width: 575px) {
    .page_register .text.notes {
      padding: 15px;
      margin-bottom: 40px; } }
  .page_register .text.notes h3 {
    margin-bottom: 20px; }
    @media screen and (max-width: 575px) {
      .page_register .text.notes h3 {
        margin-bottom: 15px; } }
  .page_register .text.notes ul {
    list-style: disc;
    padding-left: 1.25em; }
    .page_register .text.notes ul li {
      margin-bottom: 10px; }

.page_register #swpm-registration-form table, .page_register #swpm-registration-form tr, .page_register #swpm-registration-form th, .page_register #swpm-registration-form td, .page_register #swpm-registration-form label {
  display: block; }

.page_register #swpm-registration-form tbody {
  display: flex;
  flex-direction: column; }
  .page_register #swpm-registration-form tbody .swpm-registration-username-row,
  .page_register #swpm-registration-form tbody .swpm-registration-email-row,
  .page_register #swpm-registration-form tbody .swpm-registration-password-row,
  .page_register #swpm-registration-form tbody .swpm-registration-password-retype-row,
  .page_register #swpm-registration-form tbody .swpm-registration-lastname-row {
    order: 1; }
  .page_register #swpm-registration-form tbody .swpm-registration-firstname-row {
    order: 2; }

.page_register #swpm-registration-form label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px; }
  @media screen and (max-width: 575px) {
    .page_register #swpm-registration-form label {
      margin-bottom: 5px; } }

.page_register #swpm-registration-form input {
  width: 100%;
  font-size: 30px;
  padding: 10px 15px;
  border: solid 6px #DEDDDE; }
  @media screen and (max-width: 575px) {
    .page_register #swpm-registration-form input {
      font-size: 20px;
      padding: 5px 10px; } }
  .page_register #swpm-registration-form input[type="submit"] {
    background-color: #B9293B;
    color: white;
    font-weight: bold;
    padding: 20px 15px;
    border: none;
    margin-top: 50px; }
    @media screen and (max-width: 575px) {
      .page_register #swpm-registration-form input[type="submit"] {
        padding: 15px; } }

.page_register #swpm-registration-form tr {
  margin-bottom: 35px; }
  @media screen and (max-width: 575px) {
    .page_register #swpm-registration-form tr {
      margin-bottom: 25px; } }

.page_register #swpm-registration-form .swpm-registration-membership-level-row {
  display: none; }

.page_register .btn_to_top_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 133px;
  font-size: 40px;
  padding: 0 25px;
  background-color: #190C02;
  background-image: url(../images/btn_to_top_page.jpg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  color: white;
  font-size: 36px;
  text-align: center;
  border: none;
  margin-top: 50px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .page_register .btn_to_top_page {
      background-image: url(../images/btn_to_top_page@2x.jpg); } }
  .page_register .btn_to_top_page::before {
    content: "サロントップページへ行く!"; }
  @media screen and (max-width: 767px) {
    .page_register .btn_to_top_page {
      height: 90px;
      font-size: 32px; } }
  @media screen and (max-width: 575px) {
    .page_register .btn_to_top_page {
      height: 80px;
      font-size: 28px; }
      .page_register .btn_to_top_page::before {
        content: "サロントップへ"; } }

.page_register .swpm-payment-button .stripe-button-el {
  display: block;
  margin: auto; }
  .page_register .swpm-payment-button .stripe-button-el span {
    height: 60px;
    line-height: 60px;
    font-size: 26px;
    padding: 0 50px; }

.page_register .swpm-registration-widget-form {
  max-width: 640px;
  margin: auto; }
  @media screen and (max-width: 991px) {
    .page_register .swpm-registration-widget-form .formError {
      position: static !important;
      margin-top: 0 !important; } }

.swpm-edit-profile-form {
  max-width: 640px;
  margin: auto; }
  @media screen and (max-width: 991px) {
    .swpm-edit-profile-form .formError {
      position: static !important;
      margin-top: 0 !important; } }

.page_col1 #swpm-pw-reset-form label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px; }
  @media screen and (max-width: 575px) {
    .page_col1 #swpm-pw-reset-form label {
      margin-bottom: 5px; } }

.page_col1 #swpm-pw-reset-form input {
  width: 100%;
  font-size: 30px;
  padding: 10px 15px;
  border: solid 6px #DEDDDE; }
  @media screen and (max-width: 575px) {
    .page_col1 #swpm-pw-reset-form input {
      font-size: 20px;
      padding: 5px 10px; } }
  .page_col1 #swpm-pw-reset-form input[type="submit"] {
    background-color: #B9293B;
    color: white;
    font-weight: bold;
    padding: 20px 15px;
    border: none;
    margin-top: 50px; }
    @media screen and (max-width: 575px) {
      .page_col1 #swpm-pw-reset-form input[type="submit"] {
        padding: 15px; } }

.page_col1 #swpm-editprofile-form table, .page_col1 #swpm-editprofile-form tr, .page_col1 #swpm-editprofile-form th, .page_col1 #swpm-editprofile-form td, .page_col1 #swpm-editprofile-form label {
  display: block; }

.page_col1 #swpm-editprofile-form tbody {
  display: flex;
  flex-direction: column; }
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-username-row > td:last-of-type {
    font-size: 30px; }
    .page_col1 #swpm-editprofile-form tbody .swpm-profile-username-row > td:last-of-type::after {
      content: " さん"; }
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-username-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-email-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-password-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-password-retype-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-lastname-row {
    order: 1; }
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-firstname-row {
    order: 2; }
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-phone-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-street-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-city-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-state-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-zipcode-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-country-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-company-row,
  .page_col1 #swpm-editprofile-form tbody .swpm-profile-membership-level-row {
    display: none; }

.page_col1 #swpm-editprofile-form label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px; }
  @media screen and (max-width: 575px) {
    .page_col1 #swpm-editprofile-form label {
      margin-bottom: 5px; } }
  .page_col1 #swpm-editprofile-form label[for="user_name"]::after {
    content: none;
    display: inline-block;
    min-width: 118px;
    color: white;
    font-size: 14px;
    text-align: center;
    padding: 5px 10px;
    vertical-align: 2px;
    border-radius: 100px;
    margin-left: 2em; }
    @media screen and (max-width: 575px) {
      .page_col1 #swpm-editprofile-form label[for="user_name"]::after {
        min-width: 100px;
        font-size: 13px;
        padding: 2px 5px;
        margin-left: 1em; } }

.page_col1 #swpm-editprofile-form input {
  width: 100%;
  font-size: 30px;
  padding: 10px 15px;
  border: solid 6px #DEDDDE; }
  @media screen and (max-width: 575px) {
    .page_col1 #swpm-editprofile-form input {
      font-size: 20px;
      padding: 5px 10px; } }
  .page_col1 #swpm-editprofile-form input[type="submit"] {
    background-color: #B9293B;
    color: white;
    font-weight: bold;
    padding: 20px 15px;
    border: none;
    margin-top: 50px; }
    @media screen and (max-width: 575px) {
      .page_col1 #swpm-editprofile-form input[type="submit"] {
        padding: 15px; } }

.page_col1 #swpm-editprofile-form tr {
  margin-bottom: 35px; }
  @media screen and (max-width: 575px) {
    .page_col1 #swpm-editprofile-form tr {
      margin-bottom: 25px; } }

.page_col1 #swpm-editprofile-form .swpm-profile-account-delete-section {
  margin-top: 50px; }
  .page_col1 #swpm-editprofile-form .swpm-profile-account-delete-section .swpm-account-delete-button {
    color: black !important;
    font-size: 24px;
    font-weight: bold; }
    @media screen and (max-width: 575px) {
      .page_col1 #swpm-editprofile-form .swpm-profile-account-delete-section .swpm-account-delete-button {
        font-size: 20px; } }

.page_col1[data-level="2"] #swpm-editprofile-form label[for="user_name"]::after {
  content: "仮登録";
  background-color: #658E3B; }

.page_col1[data-level="3"] #swpm-editprofile-form label[for="user_name"]::after {
  content: "有料会員";
  background-color: #B9293B; }

.page_col1 section.privacy_policy,
.page_col1 .act_on_specified_commercial_transactions {
  font-size: 16px;
  line-height: 2; }
  .page_col1 section.privacy_policy h3, .page_col1 section.privacy_policy p,
  .page_col1 .act_on_specified_commercial_transactions h3,
  .page_col1 .act_on_specified_commercial_transactions p {
    margin-bottom: 1.5em; }
  .page_col1 section.privacy_policy dl.clause > div,
  .page_col1 .act_on_specified_commercial_transactions dl.clause > div {
    margin-bottom: 1.5em; }
  .page_col1 section.privacy_policy dl.clause dt,
  .page_col1 .act_on_specified_commercial_transactions dl.clause dt {
    font-weight: bold; }
  .page_col1 section.privacy_policy dl.clause dd ol,
  .page_col1 .act_on_specified_commercial_transactions dl.clause dd ol {
    list-style: none;
    counter-reset: ol;
    padding-left: 2em; }
    .page_col1 section.privacy_policy dl.clause dd ol li,
    .page_col1 .act_on_specified_commercial_transactions dl.clause dd ol li {
      counter-increment: ol; }
      .page_col1 section.privacy_policy dl.clause dd ol li p,
      .page_col1 .act_on_specified_commercial_transactions dl.clause dd ol li p {
        margin-bottom: 0; }
        .page_col1 section.privacy_policy dl.clause dd ol li p:first-of-type::before,
        .page_col1 .act_on_specified_commercial_transactions dl.clause dd ol li p:first-of-type::before {
          content: counter(ol);
          display: inline-block;
          box-sizing: border-box;
          width: 2em;
          margin-left: -2em; }
      .page_col1 section.privacy_policy dl.clause dd ol li:nth-of-type(-n + 9) p:first-of-type::before,
      .page_col1 .act_on_specified_commercial_transactions dl.clause dd ol li:nth-of-type(-n + 9) p:first-of-type::before {
        padding-left: .25em; }

.page_col2 #cols {
  display: flex; }
  @media screen and (max-width: 767px) {
    .page_col2 #cols {
      display: block; } }
  .page_col2 #cols #col_main {
    flex: 1 1 0%;
    min-width: 0; }
    .page_col2 #cols #col_main article {
      margin-bottom: 100px;
      /* wordpress editor -------- */ }
      @media screen and (max-width: 575px) {
        .page_col2 #cols #col_main article {
          margin-bottom: 80px; } }
      .page_col2 #cols #col_main article .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        max-width: 100%;
        height: auto;
        margin-bottom: 50px; }
        .page_col2 #cols #col_main article .embed-container iframe, .page_col2 #cols #col_main article .embed-container object, .page_col2 #cols #col_main article .embed-container embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
      .page_col2 #cols #col_main article .editor .aligncenter {
        display: block;
        margin: 0 auto;
        padding-bottom: 20px; }
      .page_col2 #cols #col_main article .editor .alignright {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px; }
      .page_col2 #cols #col_main article .editor .alignleft {
        float: left;
        padding-right: 20px;
        padding-bottom: 20px; }
      .page_col2 #cols #col_main article .editor img[class*="wp-image-"], .page_col2 #cols #col_main article .editor img[class*="attachment-"] {
        max-width: 100%;
        height: auto; }
      .page_col2 #cols #col_main article .editor a {
        color: #03c;
        text-decoration: none; }
        .page_col2 #cols #col_main article .editor a:hover {
          color: #69f; }
      .page_col2 #cols #col_main article .editor h1 {
        margin: 50px 0 40px;
        font-size: 175%;
        font-weight: bold; }
      .page_col2 #cols #col_main article .editor h2 {
        margin: 40px 0 30px;
        font-size: 150%;
        font-weight: bold; }
      .page_col2 #cols #col_main article .editor h3 {
        margin: 30px 0 20px;
        font-size: 125%;
        font-weight: bold; }
      .page_col2 #cols #col_main article .editor p, .page_col2 #cols #col_main article .editor ul, .page_col2 #cols #col_main article .editor ol, .page_col2 #cols #col_main article .editor table {
        font-size: 100%;
        line-height: 2em;
        margin-bottom: 20px; }
      .page_col2 #cols #col_main article .editor li {
        margin-bottom: 10px; }
      .page_col2 #cols #col_main article .editor ul {
        list-style-type: disc;
        padding-left: 1.5em; }
      .page_col2 #cols #col_main article .editor ol {
        list-style-type: decimal;
        padding-left: 1.5em; }
      .page_col2 #cols #col_main article .editor hr {
        margin: 20px 0;
        border: none;
        border-top: 1px solid #999; }
      .page_col2 #cols #col_main article .editor table {
        width: 100%;
        border-right: 1px solid #b5b5b5;
        border-top: 1px solid #b5b5b5; }
      .page_col2 #cols #col_main article .editor th {
        padding: 10px 20px;
        border-left: 1px solid #b5b5b5;
        border-bottom: 1px solid #b5b5b5;
        background-color: #eee; }
      .page_col2 #cols #col_main article .editor td {
        padding: 10px 20px;
        border-left: 1px solid #b5b5b5;
        border-bottom: 1px solid #b5b5b5; }
      .page_col2 #cols #col_main article .editor strong {
        font-weight: bold; }
      .page_col2 #cols #col_main article .editor em {
        font-style: italic; }
      .page_col2 #cols #col_main article .editor del {
        text-decoration: line-through; }
  .page_col2 #cols #col_side {
    width: 200px;
    margin-left: 40px; }
    @media screen and (max-width: 767px) {
      .page_col2 #cols #col_side {
        width: auto;
        margin-left: 0; } }
    .page_col2 #cols #col_side dl.sidebar {
      margin-top: 50px; }
      @media screen and (max-width: 767px) {
        .page_col2 #cols #col_side dl.sidebar {
          margin-top: 120px; } }
      .page_col2 #cols #col_side dl.sidebar > div {
        margin-bottom: 100px; }
        @media screen and (max-width: 575px) {
          .page_col2 #cols #col_side dl.sidebar > div {
            margin-bottom: 80px; } }
      .page_col2 #cols #col_side dl.sidebar dt {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 30px; }
        @media screen and (max-width: 767px) {
          .page_col2 #cols #col_side dl.sidebar dt {
            font-size: 16px; } }
        .page_col2 #cols #col_side dl.sidebar dt::after {
          content: "　→"; }
      .page_col2 #cols #col_side dl.sidebar dd ul li {
        font-size: 13px;
        margin-bottom: 20px; }
        @media screen and (max-width: 767px) {
          .page_col2 #cols #col_side dl.sidebar dd ul li {
            font-size: 16px; } }
        .page_col2 #cols #col_side dl.sidebar dd ul li a {
          display: block;
          transition: opacity .25s; }
          .page_col2 #cols #col_side dl.sidebar dd ul li a:hover {
            opacity: .75; }
        .page_col2 #cols #col_side dl.sidebar dd ul li .post_title {
          margin-bottom: 10px; }
